import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import Logo from "../assets/images/logo.png"
import {useState, useEffect} from "react"
import { Link } from "react-router-dom";
import { useWeb3React, Web3ReactProvider } from "@web3-react/core";
import {injectedConnector} from "../utils/connectors"
import { connectWallet } from "../utils/connectWallet";
import Onboard from 'bnc-onboard';
import Jarlathart from "../assets/images/Jarlathart.png";
import Pdf from '../assets/nft_5.pdf';
import { ethers } from "ethers";
import Web3 from "web3";
const INFURA_ID = 'a3573077-8a08-483c-b4a0-6a4544c59985'
const ETH_MAINNET_RPC = `https://mainnet.infura.io/v3/${INFURA_ID}`
const ETH_RINKEBY_RPC = `https://rinkeby.infura.io/v3/${INFURA_ID}`
const MAINNET_RPC_URL = 'https://matic-mainnet.chainstacklabs.com'


function Header(){
const [navbar, setNavbar] = useState(false);
const [loaded, setLoaded] = useState(false)
const [connect, setConnect] = useState(false)
const loadProvider = async () => {
  try {
    const web3Modal = new web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    return provider.getSigner();
  } catch (e) {
    console.log("loadProvider default: ", e);
  }
};

const {
  connector,
  library,
  account,
  chainId,
  activate,
  deactivate,
  active,
  errorWeb3Modal,
  active: networkActive, error: networkError, activate: activateNetwork
} = useWeb3React();

// useEffect(() => {
//   injectedConnector
//     .isAuthorized()
//     .then((isAuthorized) => {
//       setLoaded(true)
//       if (isAuthorized && !networkActive && !networkError) {
//         activateNetwork(injectedConnector)
//       }
//     })
//     .catch(() => {
//       setLoaded(true)
//     })
// }, [activateNetwork, networkActive, networkError])

let provider
const onboard = Onboard({
  dappId: INFURA_ID, //e5dce034-797e-4871-8a93-ef69730dca19
  networkId: 3,
  darkMode: true,
  subscriptions: {
    wallet: async (wallet) => {
      // if (wallet.provider) {
      //   setConnect(true)
      // } else {
      //   setConnect(false)
      // }
    }
  },
  walletSelect: {
    wallets:[
      {
        walletName: "metamask",
        preferred: true,
      },
      {
        walletName: "trust",
        preferred: true,
      },
      {
        walletName: 'walletConnect',
        preferred: true,
        infuraKey: INFURA_ID,
        bridge: 'https://bridge.walletconnect.org',
      },
      {
        walletName: 'torus',
      },
      {
        walletName: 'tally',
      },
      {
        walletName: 'status',
      },
      {
        walletName: 'alphawallet',
      },
      {
        walletName: 'atoken',
      },
      {
        walletName: 'blockwallet',
      },
      {
        walletName: 'coinbase',
      }
    ]
  }
})
const connectWalletPressed = async () => {
  let wallet = await onboard.walletSelect();
  await onboard.walletCheck();
};

// useEffect(async() => {
//     window.addEventListener("scroll", () => {
//       if (window.pageYOffset > 80) {
//         setNavbar(true);
//       } else {
//         setNavbar(false);
//       }
//     });

//   }, []);

    return(

        <>
        
        <Navbar collapseOnSelect expand="lg" className={ navbar ? "custom-nav active" : "custom-nav"}>
            <Container className="justify-content-between">

            <Link to={"/"} className="logo">
                <img src={Jarlathart}/>
            </Link>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{filter:"invert(100%)"}} />
            <Navbar.Collapse id="responsive-navbar-nav"  className="justify-content-end">
               
         
                <Nav >
                <Link to={"/"} href="#">Home</Link>
                <Link to={"/about"} href="#">About</Link>
                {/* <Link to={"#"} href="#">Collection</Link> */}
                <Link style={{paddingRight:"10px"}} to={"/faq"} href="#">FAQ</Link>
                <Link style={{paddingRight:"10px"}} to={"#"} href="#" onClick={() => window.open(Pdf)}>White Paper</Link>
                {/* <Link to={"/giveaway"} href="#">Give Away</Link> */}

                { active
                ?(
                  <button  className="custom-btn primary-btn" onClick={(e)=>connectWalletPressed()}>Connected</button>
                ): <button onClick={(e) => {
                  connectWalletPressed();
                }} className="custom-btn primary-btn" style={{marginTop:"15px"}}>Connect</button>
                }

                {/* <button  className="custom-btn primary-btn">Connect</button> */}
                </Nav>
            </Navbar.Collapse>
            </Container>
        </Navbar>

        </>

    )



}

export default Header;