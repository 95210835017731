import { Col, Container, Row, Accordion } from "react-bootstrap";
import MainSection from "../components/Mainsection"
import Collection from "../components/Collection"
import Faq from "../components/Faq";
import Team from "../components/Team";
import portfolio from "../assets/images/nftimg1.png"
import Roadmap from "../components/Roadmap";
import banner from "../assets/images/banner1.png"
import { connectWallet } from "../utils/connectWallet";
import { useWeb3React } from "@web3-react/core";
import { ethers, BigNumber } from "ethers";
import Web3Modal from "web3modal";
import { nft_addess , crowdSale_addess } from "../contract/addresses";
import NFTAbi from "../contract/NFT.json"
import NFTCrowdSale from '../contract/NFTCrowdSale.json'




function Home(props){
    const {
        connector,
        library,
        account,
        chainId,
        activate,
        deactivate,
        active,
        errorWeb3Modal,
        active: networkActive, error: networkError, activate: activateNetwork
      } = useWeb3React();

      const loadProvider = async () => {
        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();
            const provider = new ethers.providers.Web3Provider(connection);
            return provider.getSigner();
        } catch (e) {
            console.log("loadProvider default: ", e);
        }
    };

      const CreateToken = async () => {
        try {
            let signer = await loadProvider()
            let NFTSaleContract = new ethers.Contract(crowdSale_addess, NFTCrowdSale, signer)
            let _value = await NFTSaleContract.getPrice(account)
            let createToken = await NFTSaleContract.buyNFTV1({value:_value})
            await createToken.wait();
        }
        catch (e) {
            console.log("error: ", e)
        }
    }

    return(

        <>
        
            {props.header}
           
                


                <div className="main">

                <div className="banner_mage" >
                <img src={banner} className="banner" />
                </div>

                {/* <Collection/> */}

                    <section className="about-section">

                        <Container >
                        <div className="section-title" style={{marginBottom:"5%"}}>
                            <h2>Bark Crypto Pug</h2>
                        </div>
                            <Row>

                                <Col lg={4}>

                                <div>
                                    
                                    <img src={portfolio} className="animate"/>

                                </div>

                                </Col>

                                <Col lg={8}>
                                <div className="right-section">
                                    <div className="heading">
                                        {/* <h3>About Us</h3> */}
                                        <h2>NFT COLLECTION</h2>
                                        
                                    </div>
                                   <button className="custom-btn primary-btn"onClick={CreateToken} style={{borderRadius:"20px"}}>BUY NFT</button>

                                </div>

                                {/* <p>Sed ut perspiciatis unde omnis iste natus enim ad minim veniam, quis nostrud exercit
                                        <br/><br/>
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat 
                                    nulla pariatur. Excepteur sint occae cat cupidatat non proident, sunt in culpa qui officia 
                                    dese runt mollit anim id est laborum velit esse cillum dolore eu fugiat nulla pariatu epteur sint occaecat</p> */}
                                </Col>
                            </Row>
                        </Container>

                    </section>

                    

                    <Faq/>

                    <Team/>

                    <Roadmap/>

                </div>

            {props.footer}


        </>

    )
}

export default Home;